* {
  box-sizing: border-box;
}

.screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: space-between;
}

.main {
  margin-bottom: auto;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  background-color: grey;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 24px;
  font-weight: 300;
  text-transform: uppercase;
  margin-left: 16px;
}


.action-button {
  background: white;
  outline: none;
  border-radius: 4px;
  border: 1px solid purple;
  padding: 4px;
  flex: 1;
  height: 26px;
}
.action-button-toggle {
  font-size: 16px;
  text-decoration: underline;
  text-decoration-color: red;
}
.action-button-small {
  flex: 0;
  white-space: nowrap;
}

label {
  font-size: 8px;
  color: grey;
  text-transform: uppercase;
}
.error {
  color: red;
  font-size: 10px;
  text-align: center;
  margin: 8px 0;
}



.home {
  width: 80%;
  display: flex;
  flex-direction: column;
}
h1 {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  margin-left: 16px;
}
h4 {
  font-weight: 300;
  line-height: 1.5;
  white-space: nowrap;
}
.header-button {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
p {
  font-weight: 300;
  line-height: 1.5;
}

.form-text {
  font-size: 16px;
  margin-bottom: 12px;
}
.link {
  color: grey;
  text-decoration: none;
}