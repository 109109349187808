.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DDD;
  padding: 8px;
  background-color: white;
}

.search, .menu {
  background-color: #EEE;
  border: 1px solid #DDD;
  border-radius: 24px;
  width: 120px;
  outline: none;
  padding: 4px;
  margin-left: 16px;
  margin-bottom: 0;
}

.search {
  width: 240px;
}

.search-results {
  position: absolute;
  top: 26px;
  left: 18px;
  border: 1px solid purple;
  background-color: white;
  width: 240px;
  padding: 6px;
}

.search-results div:not(:last-child) {
  margin-bottom: 6px;
}