.relation-header {
  font-size: 20px;
  font-weight: 300;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.relation {
  border-collapse: collapse;
  width: 100%;
}

.relation th {
  padding: 8px;
  border: 1px solid black;
  background-color: grey;
}

.relation td {
  border: 1px solid lightgrey;
  background: #EEE;
  padding: 8px;
}
