.record-form {
  background: white;
  position: absolute;
  right: 0;
  top: 24px;
  border: 1px solid purple;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  cursor: initial;
  z-index: 2;
}

.record-form input, textarea, select {
  border: 1px solid purple;
  outline: none;
  padding: 4px;
  margin-bottom: 12px;
  border-radius: 4px;
}

.record-table {
  width: 100%;
}

.record-table th {
  padding: 16px;
  border: none;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
}

.record-table td {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid lightgrey;
  background: #EEE;
  padding: 8px;
}

.record-table td:hover {
  background: #DDD;
  cursor: pointer;
}

.record {
  width: 80%;
}

.record-header {
  font-size: 20px;
  font-weight: 300;
  border: 1px solid grey;
  background-color: lightgray;
  padding: 4px 8px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.record-details-main {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-top: none;
}

.record-details-main li {
  margin-bottom: 8px;
}

.record-details {
  padding: 8px;

}

.record-details ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.record-photo {
  border: 2px dashed grey;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-size: 10px;
  outline: none;
}

.record-photo-empty {
  width: 120px;
  height: 120px;
}

.record-description {
  padding: 8px;
  border: 1px solid lightgrey;
  border-top: none;
}

.record-controls {
  position: relative;
}
