.sign-options-buttons {
  display: flex;
  margin-bottom: 12px;
  justify-content: stretch;
  width: 100%;
}

.account {
  display: flex;
  align-items: center;
  border: 1px solid purple;
  border-radius: 24px;
  padding: 0 8px;
  position: relative;
  margin-left: auto;
  margin-right: 16px;
  z-index: 1;
  outline: none;
}

.account-text {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 6px;
}
